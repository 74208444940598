import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { FaDiscord } from "react-icons/fa";
import { CaretDownOutlined, GlobalOutlined } from "@ant-design/icons";
import { LanguageContext } from "./LanguageContext";
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, useMediaQuery, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import Intro from "./Intro";
import ToolTab from "./ToolTab";

function Header() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [currentDropdown, setCurrentDropdown] = useState("");
  const { setLanguage } = useContext(LanguageContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuClick = (event, menu) => {
    if (currentDropdown === menu && Boolean(anchorEl)) {
      setAnchorEl(null);
      setCurrentDropdown("");
    } else {
      setAnchorEl(event.currentTarget);
      setCurrentDropdown(menu);
    }
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMobileMoreAnchorEl(null);
    setCurrentDropdown("");
  };

  const handleLanguageChange = (lang) => {
    setLanguage(lang);
    handleMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      {currentDropdown === "Tool" && ToolTab.map((item, index) => (
        <MenuItem key={index} component={Link} to={item.path} onClick={handleMenuClose}>
          {item.label}
        </MenuItem>
      ))}
      {currentDropdown === "JobHunt" && Intro.map((item, index) => (
        <MenuItem key={index} component={Link} to={item.path} onClick={handleMenuClose}>
          {item.label}
        </MenuItem>
      ))}
      {currentDropdown === "Contact" && (
        <>
          <MenuItem component="a" href="https://discord.gg/ud6RpY39nG" onClick={handleMenuClose}>Discord</MenuItem>
          <MenuItem component={Link} to="/Advise" onClick={handleMenuClose}>More</MenuItem>
          <MenuItem component={Link} to="/Coffee" onClick={handleMenuClose}>Coffee</MenuItem>
        </>
      )}
      {currentDropdown === "Language" && (
        <>
          <MenuItem onClick={() => handleLanguageChange("CN")}>Chinese</MenuItem>
          <MenuItem onClick={() => handleLanguageChange("EN")}>English</MenuItem>
          <MenuItem onClick={() => handleLanguageChange("JP")}>Japanese</MenuItem>
        </>
      )}
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={(e) => handleMenuClick(e, "Tool")}>Tool</MenuItem>
      <MenuItem onClick={(e) => handleMenuClick(e, "JobHunt")}>JobHunt</MenuItem>
      <MenuItem onClick={(e) => handleMenuClick(e, "Contact")}>Contact</MenuItem>
      <MenuItem onClick={(e) => handleMenuClick(e, "Language")}>Language</MenuItem>
    </Menu>
  );

  return (
    <AppBar position="fixed" sx={{ background: "#333", zIndex: 100000, height: "0px" }}>
      <Toolbar sx={{ justifyContent: "space-between", minHeight: "64px" }}>
        <Typography
          component={Link}
          to="/"
          sx={{
            textDecoration: "none",
            color: "#fff",
            fontWeight: "bold",
            fontSize: "1.2rem"
          }}
        >
          Gpt-Work
        </Typography>

        {isMobile ? (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleMobileMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        ) : (
          <div>
            <Button
              color="inherit"
              onClick={(e) => handleMenuClick(e, "Tool")}
              sx={{ textTransform: "none", mr: 2 }}
            >
              Tool <CaretDownOutlined />
            </Button>
            <Button
              color="inherit"
              onClick={(e) => handleMenuClick(e, "JobHunt")}
              sx={{ textTransform: "none", mr: 2 }}
            >
              JobHunt <CaretDownOutlined />
            </Button>
            <Button
              color="inherit"
              onClick={(e) => handleMenuClick(e, "Contact")}
              sx={{ textTransform: "none", mr: 2 }}
            >
              Contact <FaDiscord />
            </Button>
            <Button
              color="inherit"
              onClick={(e) => handleMenuClick(e, "Language")}
              sx={{ textTransform: "none" }}
            >
              Language <GlobalOutlined />
            </Button>
          </div>
        )}
      </Toolbar>
      {renderMenu}
      {renderMobileMenu}
    </AppBar>
  );
}

export default Header;