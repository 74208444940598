import React from 'react';
import Footer from '../component/Footer.jsx';
import Nav from '../component/Nav.jsx';
import KEI from './keigo.jsx';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // 初始化状态
    };
  }

  render() {
    return (
      <div>
        <Nav/>
        <KEI/> 
        <Footer/>
      </div>
    );
  }
}

export default App;
