const Intro = [
    { path: "/introduction", label: "Howtouse" },
    { path: "/GptSelfcheck", label: "Gpt-Selfcheck" },
    { path: "/GptResume", label: "Gpt-Resume" },
    { path: "/GptCompany", label: "Gpt-Company" },
    { path: "/GptInterview", label: "Gpt-Interview" },
    { path: "/Gptshiboriyu", label: "Gpt-shiboriyu" },
  ];


  export default Intro