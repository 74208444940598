import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Spin } from "antd";
import "../component/Automail.css";
import CopyButton from "../component/CopyButton";
import { useApiKey } from "../component/API";
import { LanguageContext } from "../component/LanguageContext";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

function App() {
  const { language: Language } = useContext(LanguageContext);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [show1, setshow1] = useState(false);
  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false);
  const [inputvalue, setInputValue] = useState("人工智能领域");
  const apiKey = useApiKey();

  const text = useMemo(() => ({
    EN: {
      studyPlan: "FastLearn",
      generatePlan: "Enter the field you want to study quickly",
      message:
        "I want to learn about " +
        inputvalue +
        ".Identify and share the most important 20% of learnings from this topic that will help me understand 80% of it.",
      Prev: "field of artificial intelligence",
    },
    JP: {
      studyPlan: "迅速学習",
      generatePlan: "学びたい分野を記入",
      message:
        "私は" +
        inputvalue +
        "このトピックの学習のうち最も重要な 20% を特定して共有してください。それは私がその 80% を理解するのに役立ちます",
      Prev: "人工知能の分野",
    },
    CN: {
      studyPlan: "快速学习",
      generatePlan: "输入你想快速学习的领域",
      message:
        "我想学习关于" +
        inputvalue +
        "的知识。请确定并分享这个主题中最重要的20%的学习内容,这将帮助我理解其中的80%",
      Prev: "人工智能领域",
    },
    }),
    [inputvalue]
  );
  

  useEffect(() => {
      setInputValue(text[Language].Prev);
    }, [Language, text]);
  const handleSend = async (message) => {
    if (message === "要件を選びください") {
      return;
    }
    const newMessage = {
      //promp的输入内容
      message: text[Language].message,
      direction: "outgoing",
      sender: "user",
    };

    setIsTyping(true);
    await processMessageToChatGPT([...messages, newMessage], () => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsTyping(false);
    });
  };

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = [];
    for (const messageObject of chatMessages) {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      apiMessages.push({ role, content: messageObject.message });
    }
    const apiRequestBody = {
      model: "gpt-3.5-turbo-0125",
      messages: [...apiMessages],
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    const data = await response.json();
    const messageText = data.choices[0].message.content;
    const messageChars = messageText.split("");
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      setMessages([
        {
          message: messageText.slice(0, currentIndex + 1),
          sender: "ChatGPT",
        },
      ]);
      currentIndex++;
      if (currentIndex === messageChars.length) {
        clearInterval(intervalId);
        setIsTyping(false);
      }
    }, 30);
  }

  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>{text[Language].studyPlan}</p>
      <h2>{text[Language].generatePlan}</h2>

      <div className="Container p5">
        <input
          className="input-with-original-styles"
          type="text"
          value={inputvalue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
      <div className="Text">
        <Button
          type="primary"
          placeholder=""
          onClick={() => {
            if (!hasTriggeredEvent) {
              handleSend();
              setHasTriggeredEvent(true);
              setTimeout(() => {
                setHasTriggeredEvent(false);
              }, 1000);
              if (show1 === false) {
                setshow1(true);
              }
            }
          }}
          disabled={isTyping}
          style={{ pointerEvents: isTyping ? "none" : "auto" }}
        >
          {isTyping ? <Spin /> : "RUN"}
        </Button>
        {show1 && (
          <div className="Container">
            <MainContainer>
              <ChatContainer>
                <MessageList
                  scrollBehavior="smooth"
                  typingIndicator={
                    isTyping ? <TypingIndicator content="working" /> : null
                  }
                >
                  {messages.map((message, i) => {
                    return <Message key={i} model={message} />;
                  })}
                </MessageList>
              </ChatContainer>
            </MainContainer>

            {!isTyping && <CopyButton istyping={false} messages={messages} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
