import React, { useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import "../component/Automail.css";
import { LanguageContext } from "../component/LanguageContext";

function App() {
  const { language: Language } = useContext(LanguageContext);
  
  const text = useMemo(() => ({
    EN: {
      studyPlan: "How to use JobHunt",
      p1: "Finding a job is not easy and boring",
      p2: "How to get an offer in a short time?",
      p3: "1. First of all, you need to know yourself and determine the position you want to apply for",
      p4: <Link to="/GptSelfcheck">Enter here</Link>,
      p5: "2. Then generate your resume",
      p6: <Link to="/GptResume">Enter here</Link>,
      p7: "3. Analyze the company you are interested in",
      p8: <Link to="/GptCompany">Enter here</Link>,
      p9: "4. Prepare for the interview",
      p10: <Link to="/GptInterview">Enter here</Link>,
      p11: "Congratulations! This step has surpassed 95% of people",
      p12: " have fully prepared, and I just need to interview calmly",
    },
    JP: {
      studyPlan: "使い方",
      p1: "仕事を見つけるのは簡単ではないし、退屈である",
      p2: "短時間で内定を獲得するにはどうればいいですか?",
      p3: "1. まずは自分を知り、応募したいポジションを決める必要があります。",
      p4: <Link to="/GptSelfcheck">Enter</Link>,
      p5: "2. 次に履歴書を作成します",
      p6: <Link to="/GptResume">Enter</Link>,
      p7: "3. 興味のある企業を分析する",
      p8: <Link to="/GptCompany">Enter</Link>,
      p9: "4. 面接の準備をする",
      p10: <Link to="/GptInterview">Enter</Link>,
      p11: "おめでとうございます! このステップは 95% を超えました",
      p12: "準備は万全、あとは落ち着いて面接するだけ",
    },
    CN: {
      studyPlan: "怎么使用找工作",
      p1: "找工作是一件不容易并且枯燥的事情",
      p2: "如何在短时间内拿到offer呢?",
      p3: "1.首先你需要认识自己并确定要应聘的岗位",
      p4: <Link to="/GptSelfcheck">进入这里</Link>,
      p5: "2.然后生成你的简历",
      p6: <Link to="/GptResume">进入这里</Link>,
      p7: "3.分析你感兴趣的公司",
      p8: <Link to="/GptCompany">进入这里</Link>,
      p9: "4.进行面试的准备",
      p10: <Link to="/GptInterview">进入这里</Link>,
      p11: "恭喜你!做到这一步已经超越了95%的人了",
      p12: "已经充分准备，接下来只需要从容面试",
    },
  }),[]
  );


  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>{text[Language].studyPlan}</p>
      <h2>{text[Language].p1}</h2>
      <h2>{text[Language].p2}</h2>
      <h2>{text[Language].p3}</h2>
      <h2>{text[Language].p4}</h2>
      <h2>{text[Language].p5}</h2>
      <h2>{text[Language].p6}</h2>
      <h2>{text[Language].p7}</h2>
      <h2>{text[Language].p8}</h2>
      <h2>{text[Language].p9}</h2>
      <h2>{text[Language].p10}</h2>
      <h2>{text[Language].p11}</h2>
      <h2>{text[Language].p12}</h2>
    </div>
  );
}

export default App;
