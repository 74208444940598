import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Spin, Select, Modal, Input, message } from "antd";
import "../component/Automail.css";
import CopyButton from "../component/CopyButton";
import { LanguageContext } from "../component/LanguageContext";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import EnhancedInput from "../component/InputContainer";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ChatComponent from "../component/ChatComponent";

const { Option } = Select;
const theme = createTheme();
const kei = true;
function App() {
  const { language: Language } = useContext(LanguageContext);
  const [gptmodel, setGptmodel] = useState("gpt-3.5-turbo-0125");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [show1, setshow1] = useState(false);
  const [inputvalue, setInputValue] = useState("天气真好我们去散步吧");
  const [apiKey, setApiKey] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isValidatingApiKey, setIsValidatingApiKey] = useState(false);
  const [validatedApiKey, setValidatedApiKey] = useState("");

  const text = useMemo(
    () => ({
      EN: {
        title: "Honorific",
        message: "Input text in any language",
        input: "Let's take a walk outside",
        beta: "Click here to try simplifying honorifics",
        apiKeyPrompt: "Please enter your API key to use GPT-4",
        submit: "Submit",
        cancel: "Cancel",
      },
      JP: {
        title: "敬語生成",
        message: "任意の言語で書いてください",
        input: "天気がいいから散歩しましょう",
        beta: "クリックして敬語を簡略化してみてください",
        apiKeyPrompt: "GPT-4を使用するにはAPIキーを入力してください",
        submit: "送信",
        cancel: "キャンセル",
      },
      CN: {
        title: "敬语转换",
        message: "输入想转换成敬语的文本",
        input: "天气真好我们去散步吧",
        beta: "点击尝试简化敬语",
        apiKeyPrompt: "请输入您的API密钥以使用GPT-4",
        submit: "提交",
        cancel: "取消",
      },
    }),
    []
  );

  useEffect(() => {
    setInputValue(text[Language].input);
  }, [Language, text]);

  const models = [
    { value: "gpt-3.5-turbo-0125", label: "GPT-3.5 Turbo" },
    { value: "gpt-3.5-turbo-16k", label: "GPT-3.5 Turbo 16K" },
    { value: "gpt-4o-2024-08-06", label: "gpt-4o" },
    { value: "gpt-4o-mini", label: "gpt-4o-mini" },
  ];

  const validateApiKey = async (key) => {
    setIsValidatingApiKey(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/validateApiKey`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${key}`
        },
        body: JSON.stringify({ model: 'gpt-4' })
      });

      if (!response.ok) {
        throw new Error('Invalid API key');
      }

      setValidatedApiKey(key);
      return true;
    } catch (error) {
      console.error("API key validation error:", error);
      message.error("Invalid API key. Please try again.");
      return false;
    } finally {
      setIsValidatingApiKey(false);
    }
  };

  const handleModalOk = async () => {
    if (apiKey) {
      const isValid = await validateApiKey(apiKey);
      if (isValid) {
        setIsModalVisible(false);
        handleSend();
      }
    } else {
      message.warning("Please enter a valid API key.");
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleSend = async () => {
    if (inputvalue === "要件を選びください") {
      return;
    }
  
    if (gptmodel.startsWith("gpt-4")) {
      if (!validatedApiKey) {
        setIsModalVisible(true);
        return;
      }
    }
  
    setIsTyping(true);
    setshow1(true);
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/process-keigo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': gptmodel.startsWith("gpt-4") ? `Bearer ${validatedApiKey}` : ''
        },
        body: JSON.stringify({
          inputValue: inputvalue,
          model: gptmodel
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let accumulatedMessage = "";
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n');
  
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const jsonData = JSON.parse(line.slice(5));
              if (jsonData.choices && jsonData.choices[0]) {
                const { delta } = jsonData.choices[0];
                if (delta && delta.content) {
                  accumulatedMessage += delta.content;
                  setMessages([
                    {
                      message: accumulatedMessage,
                      sender: "ChatGPT",
                    },
                  ]);
                }
              }
            } catch (jsonError) {
              console.warn("JSON parse error:", jsonError, "for line:", line);
            }
          }
        }
      }
    } catch (error) {
      console.error("Error processing message:", error);
      message.error(`An error occurred: ${error.message}`);
    } finally {
      setIsTyping(false);
    }
  };


  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <Select
        style={{ width: 200, marginBottom: 20 }}
        value={gptmodel}
        onChange={(value) => setGptmodel(value)}
      >
        {models.map((model) => (
          <Option key={model.value} value={model.value}>
            {model.label}
          </Option>
        ))}
      </Select>

      <p>{text[Language].title}</p>
      <h2>{text[Language].message}</h2>
      <a
        href="/simple"
        style={{ textDecoration: "underline" }}
      >
        <h2>{text[Language].beta}</h2>
      </a>

      <div className="Container p5">
        <EnhancedInput
          inputValue={inputvalue}
          setInputValue={setInputValue}
          kei={kei}
        />
      </div>
      <div className="Text">
        <Button
          type="primary"
          onClick={handleSend}
          disabled={isTyping}
          style={{ pointerEvents: isTyping ? "none" : "auto" }}
        >
          {isTyping ? <Spin /> : "RUN"}
        </Button>
        {show1 && (
          <div className="Container">
            <ThemeProvider theme={theme}>
              <ChatComponent messages={messages} isTyping={isTyping} />
            </ThemeProvider>

            {!isTyping && <CopyButton istyping={false} messages={messages} />}
          </div>
        )}
      </div>

      <Modal
        title={text[Language].apiKeyPrompt}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText={text[Language].submit}
        cancelText={text[Language].cancel}
        confirmLoading={isValidatingApiKey}
      >
        <Input
          placeholder="Enter your API key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          style={{ marginBottom: 16 }}
        />
      </Modal>
    </div>
  );
}

export default App;