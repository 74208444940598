import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import IN from '../pages/Involve';

const BAA = () => {
    return (
        <div>
            <Nav />
            <IN />
            <Footer />
            
        </div>
    )
}

export default BAA