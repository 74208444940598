import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import DES from '../pages/Decision'

const DE = () => {
    return (
        <div>
            <Nav />
            <DES />
            <Footer />
            
        </div>
    )
}

export default DE