import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Spin } from "antd";
import "../component/Automail.css";
import CopyButton from "../component/CopyButton";
import { useApiKey } from "../component/API";
import { LanguageContext } from "../component/LanguageContext";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

function App() {
  const { language: Language } = useContext(LanguageContext);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [show1, setshow1] = useState(false);
  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false);
  const [inputvalue, setInputValue] = useState(
    "1.个人信息：\n2.职业目标(希望找到什么类型的工作):\n3.教育背景：\n4.工作经验: \n5.技能: \n6.证书和许可:\n7.志愿者经历:\n8.荣誉奖项: \n9.个人兴趣:"
  );
  const apiKey = useApiKey();


  const text = useMemo(() => ({
    EN: {
      studyPlan: "Resume",
      generatePlan: "give you a professional resume",
      message:
        "based on" +
        inputvalue +
        ".Based on these tips, generate a copy of my professional resume",
      Prev: "1. Personal information:\n2. Career goals (what type of job do you hope to find):\n3. Education background:\n4. Work experience:\n5. Skills:\n6. Certificates and licenses:\n7. Volunteer experience：\n8. Awards: \n9. Personal interests:",
    },
    JP: {
      studyPlan: "履歴書作成",
      generatePlan: "Resumeを生成する",
      message: inputvalue + "テンプレートに基つき。職務経歴書を作成する",
      Prev: "1. 個人情報:\n2. キャリア目標 (どのような種類の仕事に就きたいですか):\n3. 学歴:\n4. 職歴:\n5. スキル:\n6. 証明書とライセンス:\n7. ボランティア経験:\n8. 受賞歴: \n9. 個人的な興味:",
    },
    CN: {
      studyPlan: "生成简历",
      generatePlan: "输入内容，给你一份专业简历",
      message: inputvalue + "基于这些提示，生成一份我的专业简历",
      Prev: "1.个人信息：\n2.职业目标(希望找到什么类型的工作):\n3.教育背景：\n4.工作经验: \n5.技能: \n6.证书和许可:\n7.志愿者经历:\n8.荣誉奖项: \n9.个人兴趣:",
    },
  }),
    [inputvalue]
  );
    useEffect(() => {
      setInputValue(text[Language].Prev);
    }, [Language, text]);



  const handleSend = async (message) => {
    if (message === "要件を選びください") {
      return;
    }
    const newMessage = {
      //promp的输入内容
      message: text[Language].message,
      direction: "outgoing",
      sender: "user",
    };

    setIsTyping(true);
    await processMessageToChatGPT([...messages, newMessage], () => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsTyping(false);
    });
  };

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = [];
    for (const messageObject of chatMessages) {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      apiMessages.push({ role, content: messageObject.message });
    }
    const apiRequestBody = {
      model: "gpt-3.5-turbo-0125",
      messages: [...apiMessages],
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    const data = await response.json();
    const messageText = data.choices[0].message.content;
    const messageChars = messageText.split("");
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      setMessages([
        {
          message: messageText.slice(0, currentIndex + 1),
          sender: "ChatGPT",
        },
      ]);
      currentIndex++;
      if (currentIndex === messageChars.length) {
        clearInterval(intervalId);
        setIsTyping(false);
      }
    }, 30);
  }

  //返回渲染
  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>

      <p>{text[Language].studyPlan}</p>
      <h2>{text[Language].generatePlan}</h2>

      <div className="Container p5">
        <textarea
          value={inputvalue}
          onChange={(e) => setInputValue(e.target.value)}
          style={{
            width: "70%",
            height: "180px",
            border: "1px solid #ccc",
            borderRadius: "4px",
            padding: "10px",
            outline: "none",
            fontSize: "14.56px",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        />
      </div>
      <div className="Text">
        <Button
          type="primary"
          placeholder=""
          onClick={() => {
            if (!hasTriggeredEvent) {
              handleSend();
              setHasTriggeredEvent(true);
              setTimeout(() => {
                setHasTriggeredEvent(false);
              }, 1000);
              if (show1 === false) {
                setshow1(true);
              }
            }
          }}
          disabled={isTyping}
          style={{ pointerEvents: isTyping ? "none" : "auto" }}
        >
          {isTyping ? <Spin /> : "RUN"}
        </Button>
        {show1 && (
          <div className="Container">
            <MainContainer>
              <ChatContainer>
                <MessageList
                  scrollBehavior="smooth"
                  typingIndicator={
                    isTyping ? <TypingIndicator content="working" /> : null
                  }
                >
                  {messages.map((message, i) => {
                    return <Message key={i} model={message} />;
                  })}
                </MessageList>
              </ChatContainer>
            </MainContainer>

            {!isTyping && <CopyButton istyping={false} messages={messages} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
