import React from 'react';
import './Footer.css'


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className='footer'>
       © {currentYear} Purumomo. All rights reserved. Powered by Purumomo AI Technologies.
    </div>
  );
};



export default Footer;
