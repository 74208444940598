import { useState, useEffect } from 'react';

export function useApiKey() {
  const [apiKey, setApiKey] = useState(null);

  useEffect(() => {
    async function fetchApiKey() {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api_key`);
      const data = await response.json();
      setApiKey(data.api_key);
    }
    fetchApiKey();
  }, []);

  return apiKey;
}
