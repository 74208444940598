import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import Intro from '../pages/intro';

const ITO = () => {
    return (
        <div>
            <Nav />
            <Intro />
            <Footer />
            
        </div>
    )
}

export default ITO