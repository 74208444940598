import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import Resume from '../pages/shiboriyu';

const RES = () => {
    return (
        <div>
            <Nav />
            <Resume />
            <Footer />
            
        </div>
    )
}

export default RES