import { useState, useMemo, useContext } from "react";
import { Button, Spin } from 'antd';
import "../component/Automail.css";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import CopyButton from "../component/CopyButton";
import { LanguageContext } from "../component/LanguageContext";
import { options } from "../component/OptionMail";
import { useApiKey } from '../component/API';
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";


function App() {
  const { language: Language } = useContext(LanguageContext);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [Optionstate, setOptionstate] = useState("");
  const [show1, setshow1] = useState(false);
  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false);
  const apiKey = useApiKey();


  const text = useMemo(
    () => ({
      EN: {
        title: "Email template",
        message: "Select the subject of the email",
        // input: "Lets take a walk outside",
      },

      JP: {
        title: "メール構成",
        message: "メールの件名を選択してください",
        // input: "天気がいいから散歩しましょう",
      },

      CN: {
        title: "邮件模板",
        message: "选择邮件的主题",
        // input: "天气真好我们去散步吧",
      },
    }),
    []
  );
  const handleSend = async (message) => {
    let newMessage;
    if (Optionstate === "【自分で他の要件を記入】") {
      const userMessage = prompt("例：出張時のお礼");
      newMessage = {
        message: userMessage + "のビジネスメールテンプレートを日本語で書いてください。",
        direction: "outgoing",
        sender: "user",
      };
    }
    else {
      newMessage = {
        message: Optionstate + "のビジネスメールテンプレートを日本語で書いてください。",
        direction: "outgoing",
        sender: "user",
      };
    }

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);
    setIsTyping(true);

    await processMessageToChatGPT(newMessages);
  };


  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = [];
    for (const messageObject of chatMessages) {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      apiMessages.push({ role, content: messageObject.message });
    }
    const apiRequestBody = {
      model: "gpt-3.5-turbo-0125",
      messages: [...apiMessages],
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    const data = await response.json();
    const messageText = data.choices[0].message.content;
    const messageChars = messageText.split("");
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      setMessages([
        {
          message: messageText.slice(0, currentIndex + 1),
          sender: "ChatGPT",
        },
      ]);
      currentIndex++;
      if (currentIndex === messageChars.length) {
        clearInterval(intervalId);
        setIsTyping(false);
      }
    }, 30);
  }



  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>{text[Language].title}</p>
      <h2>{text[Language].message}</h2>

      <div className="Container p5">
        <select
          className="Custom-select"
          onChange={(e) => {
            const selectOption = e.target.value;
            setOptionstate(selectOption);
          }}
        >
          {options}
        </select>
      </div>
      <div className="Text">
        <Button
          type="primary"
          placeholder=""
          onClick={() => {
            if (!hasTriggeredEvent) {
              handleSend();
              setHasTriggeredEvent(true);
              setTimeout(() => {
                setHasTriggeredEvent(false);
              }, 1000);
              if (show1 === false) {
                setshow1(true);
              }
            }
          }}
          disabled={isTyping}
          style={{ pointerEvents: isTyping ? "none" : "auto" }}
        >
          {isTyping ? <Spin /> : "RUN"}
        </Button>
        {show1 && (
          <div className="Container">
            <MainContainer>
              <ChatContainer>
                <MessageList
                  scrollBehavior="smooth"
                  typingIndicator={
                    isTyping ? <TypingIndicator content="working" /> : null
                  }
                >
                  {messages.map((message, i) => {
                    return <Message key={i} model={message} />;
                  })}
                </MessageList>
              </ChatContainer>
            </MainContainer>
            {!isTyping && <CopyButton istyping={false} messages={messages} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
