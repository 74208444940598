import React from 'react';
import { Box, Paper, Typography, CircularProgress, List, ListItem} from '@mui/material';

const TypingIndicator = ({ content }) => (
    <Box display="flex" alignItems="center" p={1}>
        <CircularProgress size={20} />
        <Typography variant="body2" sx={{ color: 'white', ml: 1 }}>
            {content}
        </Typography>
    </Box>
);

const Message = ({ model }) => {
    const messageLines = (model.message || 'Empty message').split('\n');

    return (
        <ListItem 
            alignItems="center" 
            sx={{ 
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}
        >
            <Paper
                elevation={1}
                sx={{
                    p: 1,
                    maxWidth: '90%', 
                    backgroundColor: 'grey.100',
                    display: 'inline-block' 
                }}
            >
                {messageLines.map((line, index) => (
                    <Typography
                        key={index}
                        variant="body1"
                        sx={{ 
                            color: 'text.primary',
                            textAlign: 'left',
                            whiteSpace: 'pre-wrap',
                            wordBreak: 'break-word'
                        }}
                    >
                        {line}
                    </Typography>
                ))}
            </Paper>
        </ListItem>
    );
};

const ChatComponent = ({ messages = [], isTyping }) => {
    return (
        <Box
            flexGrow={1}
            overflow="auto"
            p={2}
            sx={{
                '&::-webkit-scrollbar': {
                    width: '0.4em'
                },
                '&::-webkit-scrollbar-track': {
                    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
                    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(0,0,0,.1)',
                    outline: '1px solid slategrey'
                }
            }}
        >
            {messages.length === 0 ? (
                ""
            ) : (
                <List disablePadding>
                    {messages.map((message, i) => (
                        <Message key={i} model={message} />
                    ))}
                </List>
            )}
            {isTyping && (
                <Box p={1} borderTop={1} borderColor="divider">
                    <TypingIndicator style={{ color: 'white' }} content="Converting...Please Hold" />
                </Box>
            )}
        </Box>
    );
};

export default ChatComponent;