import React from "react";

 const industryOptions = [
  <option key="0" value="業界を選んでください">業界を選んでください</option>,
  <option key="1" value="金融">金融</option>,
  <option key="2" value="住宅・建材・エクステリア">住宅・建材・エクステリア</option>,
  <option key="3" value="投資信託委託・投資顧問">投資信託委託・投資顧問</option>,
  <option key="4" value="生命保険・損害保険">生命保険・損害保険</option>,
  <option key="5" value="ゲーム関連">ゲーム関連</option>,
  <option key="6" value="建設・土木">建設・土木</option>,
  <option key="7" value="広告">広告</option>,
  <option key="8" value="旅行・観光">旅行・観光</option>,
  <option key="9" value="ソフトウェア・情報処理">ソフトウェア・情報処理</option>,
  <option key="10" value="半導体・電子・電気機器">半導体・電子・電気機器</option>,
  <option key="11" value="シンクタンク・マーケティング・調査">シンクタンク・マーケティング・調査</option>,
  <option key="12" value="設計">設計</option>,
  <option key="13" value="通信関連">通信関連</option>,
  <option key="14" value="精密機器">精密機器</option>,
  <option key="15" value="公益・特殊・独立行政法人">公益・特殊・独立行政法人</option>,
  <option key="16" value="【自分で分野を選んでください】">【自分で分野を選んでください】</option>
];

export default industryOptions