import React, { useState, useContext, useEffect, useMemo } from "react";
import { Button, Spin } from "antd";
import "../component/Automail.css";
import CopyButton from "../component/CopyButton";
import { useApiKey } from "../component/API";
import { LanguageContext } from "../component/LanguageContext";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";

function App() {
  const { language: Language } = useContext(LanguageContext);
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [show1, setshow1] = useState(false);
  const [hasTriggeredEvent, setHasTriggeredEvent] = useState(false);
  const [inputvalue, setInputValue] = useState("机器学习");
  const apiKey = useApiKey();

  const text = useMemo(() => ({
    EN: {
      studyPlan: "New Skills",
      generatePlan: "Generate plan for learning new skills",
      message:
        "I want to study about " +
        inputvalue +
        "Generate a 30 day plan that will help a beginner like me learn the skill from scratch",
      Prev: "machine learning",
    },
    JP: {
      studyPlan: "新技能学習",
      generatePlan: "最初の一か月学習計画を作る",
      message:
        inputvalue +
        "について勉強したい。まず一か月の学習プランを立てください。私みたいな初心者がゼロからこのスキルを勉強することをアドバイスください",
      Prev: "機械学習",
    },
    CN: {
      studyPlan: "学习新技能",
      generatePlan: "输入想掌握的新技能并生成一个月学习计划",
      message:
        "我想学习" +
        inputvalue +
        "这个技能,请你生成一个30天的学习计划。帮助像我这样的初学者从零开始学习这项技能",
      Prev: "机器学习",
    },
  }),
  [inputvalue]);

    useEffect(() => {
      setInputValue(text[Language].Prev);
    }, [Language, text]);
  


  const handleSend = async (message) => {
    if (message === "要件を選びください") {
      return;
    }
    const newMessage = {
      //promp的输入内容
      message: text[Language].message,
      direction: "outgoing",
      sender: "user",
    };

    setIsTyping(true);
    await processMessageToChatGPT([...messages, newMessage], () => {
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setIsTyping(false);
    });
  };

  async function processMessageToChatGPT(chatMessages) {
    const apiMessages = [];
    for (const messageObject of chatMessages) {
      const role = messageObject.sender === "ChatGPT" ? "assistant" : "user";
      apiMessages.push({ role, content: messageObject.message });
    }
    const apiRequestBody = {
      model: "gpt-3.5-turbo-0125",
      messages: [...apiMessages],
    };

    const response = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    });

    const data = await response.json();
    const messageText = data.choices[0].message.content;
    const messageChars = messageText.split("");
    let currentIndex = 0;

    const intervalId = setInterval(() => {
      setMessages([
        {
          message: messageText.slice(0, currentIndex + 1),
          sender: "ChatGPT",
        },
      ]);
      currentIndex++;
      if (currentIndex === messageChars.length) {
        clearInterval(intervalId);
        setIsTyping(false);
      }
    }, 30);
  }

  //返回渲染
  return (
    <div className="App">
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>{text[Language].studyPlan}</p>
      <h2>{text[Language].generatePlan}</h2>

      <div className="Container p5">
        <input
          className="input-with-original-styles"
          type="text"
          value={inputvalue}
          onChange={(e) => setInputValue(e.target.value)}
        />
      </div>
      <div className="Text">
        <Button
          type="primary"
          placeholder=""
          onClick={() => {
            if (!hasTriggeredEvent) {
              handleSend();
              setHasTriggeredEvent(true);
              setTimeout(() => {
                setHasTriggeredEvent(false);
              }, 1000);
              if (show1 === false) {
                setshow1(true);
              }
            }
          }}
          disabled={isTyping}
          style={{ pointerEvents: isTyping ? "none" : "auto" }}
        >
          {isTyping ? <Spin /> : "RUN"}
        </Button>
        {show1 && (
          <div className="Container">
            <MainContainer>
              <ChatContainer>
                <MessageList
                  scrollBehavior="smooth"
                  typingIndicator={
                    isTyping ? <TypingIndicator content="working" /> : null
                  }
                >
                  {messages.map((message, i) => {
                    return <Message key={i} model={message} />;
                  })}
                </MessageList>
              </ChatContainer>
            </MainContainer>

            {!isTyping && <CopyButton istyping={false} messages={messages} />}
          </div>
        )}
      </div>
    </div>
  );
}

export default App;
