import React from "react";

const OptionJob = [
  <option key="0" value="職種を選んでください">職種を選んでください</option>,
  <option key="1" value="エンジニア">エンジニア</option>,
  <option key="2" value="プログラマー">プログラマー</option>,
  <option key="3" value="デザイナー">デザイナー</option>,
  <option key="4" value="アーティスト">アーティスト</option>,
  <option key="5" value="作家">作家</option>,
  <option key="6" value="コンサルタント">コンサルタント</option>,
  <option key="7" value="弁護士">弁護士</option>,
  <option key="8" value="編集者">編集者</option>,
  <option key="9" value="ビジネスマン">ビジネスマン</option>,
  <option key="10" value="【自分で他の職種を記入】">【自分で他の職種を記入】</option>
];

export default OptionJob;