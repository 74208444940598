import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import Kei from '../pages/Kei.jsx'

const KEI = () => {
    return (
        <div>
            <Nav />
            <Kei />
            <Footer />
            
        </div>
    )
}

export default KEI