import React from 'react'
import Footer from '../component/Footer';
import Nav from '../component/Nav';
import Cof from '../pages/coffee';

const KEI = () => {
    return (
        <div>
            <Nav />
            <Cof />
            <Footer />
            
        </div>
    )
}

export default KEI