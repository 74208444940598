const ToolTab = [
    { path: "/Mail", label: "Gpt-Mail" },
    { path: "/Translate", label: "Gpt-Translate" },
    { path: "/Decision", label: "Gpt-Decision" },
    { path: "/Involve", label: "Gpt-Involve" },
    { path: "/LP", label: "Gpt-LearnNewSkill" },
    { path: "/FastLearning", label: "Gpt-FastLearning" },
    { path: "/LearnFromBest", label: "Gpt-LearnFromBest" },
    { path: "/LearnTutor", label: "Gpt-LearningTutor" },
  ];


  export default ToolTab