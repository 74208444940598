import { React, useMemo, useContext } from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { LanguageContext } from "../component/LanguageContext";

const ColorfulTextField = styled(TextField)({
    width: '80%',
    maxWidth: '800px',
    margin: '20px auto',
    '& .MuiOutlinedInput-root': {
        borderRadius: 30,
        backgroundColor: '#f0f0f0',
        transition: 'all 0.3s',
        '&:hover': {
            backgroundColor: '#e0e0e0',
        },
        '&.Mui-focused': {
            backgroundColor: '#ffffff',
            boxShadow: '0 0 0 3px rgba(0, 123, 255, 0.3)',
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
});

const CoolInput = ({ inputValue, setInputValue,kei}) => {
    const { language: Language } = useContext(LanguageContext);
    const text = useMemo(
        () => ({
            EN: {
                keigoIntro: "Enter the text you want to convert to honorifics...",
                simpleIntro: "Enter the japanese text you want to simplify...",
            },
            JP: {
                keigoIntro: "敬語に変換したい文字を入力する...",
                simpleIntro: "簡略化したい日本語のテキストを入力してください..."
            },
            CN: {
                keigoIntro: "输入想转换成敬语的文本...",
                simpleIntro: "输入您想要简化的日语文本...",
            },
        }),
        []
    );
    return (
        <ColorfulTextField
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder={kei ? text[Language].keigoIntro : text[Language].simpleIntro}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon color="primary" />
                    </InputAdornment>
                ),
                endAdornment: inputValue && (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={() => setInputValue('')}
                            color="primary"
                        >
                            <CloseIcon />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

export default CoolInput;